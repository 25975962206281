import {Action, configureStore} from "@reduxjs/toolkit"
import {ThunkAction} from "redux-thunk"
import profileReducers from "../features/profile/profileSlice"
import marketplaceReducers from "../../pending/marketplace/marketplaceSlice"
import pushMessageReducers from "../features/notification/notificationSlice"

const store = configureStore({
  reducer: {
    profile: profileReducers,
    marketplace: marketplaceReducers,
    pushMessage: pushMessageReducers,
  },
})
export type RootState = ReturnType<typeof store.getState>

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
